/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"

import "uikit/dist/css/uikit.min.css"
import UIkit from "uikit"
import Icons from "uikit/dist/js/uikit-icons"
import "uikit/dist/js/uikit.min.js"
import "./layout.scss"

UIkit.use(Icons)

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div className="uk-container uk-container-small uk-margin-top">
          <main>{children}</main>
        </div>
        <div className="uk-container uk-container-small uk-margin-large-top footer"></div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
