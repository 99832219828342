import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import './header.scss'

function Header(props) {
  // Set active class to current page's navbar link
  const isActive = ({ isCurrent }) => {
    return isCurrent ? { className: 'custom nav-link-active' } : {}
  }

  return (
    <header>
      <div className="uk-container uk-container-small">
        <div className="uk-flex uk-flex-column main-header">
          <Link to="/" className="uk-heading-small site-title uk-flex-stretch">
            Miesten&shy;piiri
          </Link>
          <a
            href="https://www.vantaanseurakunnat.fi/vantaankosken-seurakunta"
            rel="noopener noreferrer"
            target="_blank"
            className={'site-subtitle uk-flex-stretch'}
          >
            Vantaan&shy;kosken srk
          </a>
        </div>
      </div>
      <div className="uk-container uk-container-small">
        <nav
          className="uk-navbar-container uk-navbar-transparent"
          uk-navbar="align:left;"
        >
          <div className="uk-navbar">
            <ul className="uk-navbar-nav">
              <li className="">
                <Link
                  className="uk-text-bold uk-text-secondary custom"
                  getProps={isActive}
                  to="/"
                >
                  Etusivu
                </Link>
              </li>
              <li className="">
                <Link
                  className="uk-text-bold uk-text-secondary custom"
                  getProps={isActive}
                  to="/info/"
                >
                  Info
                </Link>
              </li>
              <li className="">
                <Link
                  className="uk-text-bold uk-text-secondary custom"
                  getProps={isActive}
                  to="/arkisto/"
                >
                  Arkisto
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  )
}

export default Header

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}
